import Pouch from "pouchdb";
import find from 'pouchdb-find';

import { DBTherapistModel } from "../constant";
import ErrorHandler from "./ErrorHandler";
import moment from "moment";

export const dbTruncate = (db) => {
  return db
    .allDocs()
    .then(function (result) {
      return Promise.all(
        result.rows.map(function (row) {
          return db.remove(row.id, row.value.rev);
        })
      );
    })
    .then(function () {
      return true;
    })
    .catch(function (err) {
      return false;
    });
};

export function onDBChange(db, callback) {
  callback();
  return db
    .changes({
      live: true,
      since: "now",
    })
    .on("change", function (change) {
      callback();
    })
    .on("error", function (error) {
      console.error("Change listener error:", error);
    });
}

export const getServices = () => {
  const db = new Pouch("services");

  return new Promise((resolve, reject) => {
    db.allDocs({
      descending: false,
      include_docs: true,
      attachments: true,
    })
      .then((res) => {
        resolve(res.rows);
      })
      .catch((err) => reject(err));
  });
};

export const getTherapist = () => {
  const db = new Pouch("therapists");
  return new Promise((resolve, reject) => {
    db.allDocs({
      include_docs: true,
    })
      .then((res) => {
        resolve(res.rows);
      })
      .catch((err) => reject(err));
  });
};

export const setTherapist = (data) => {
  const db = new Pouch("therapists");
  dbTruncate(db).then(() => {
    db.bulkDocs(
      data.map((i, index) => ({ ...new DBTherapistModel(i), key: index }))
    ).then((res) => {
      return db.query(
        (doc, emit) => {
          emit(doc.key);
        },
        { include_docs: true, descending: false }
      );
    });
  });
};

export const syncReservations = async (serverData, date) => {
  Pouch.plugin(find);
  const currentDate = moment(date, 'LL').format('YYYY-MM-DD');
  const db = new Pouch("reservations");
  
  db.createIndex({
    index: { fields: ['reservation_at'] }
  });

  db.find({
    selector: {
      reservation_at: { 
        $gte: `${currentDate} 00:00:00`,
        $lt: `${currentDate} 23:59:00`
      },
    }
  }).then(({docs}) => {

    const localIds = docs.map( i => i.reservation_no.toString() );
    const serverIds = serverData.map( i => i.reservation_no );
    
    serverIds.map( i => {
      const newOrder = serverData.find( order => order.reservation_no == i );
      if( !localIds.includes(i) ){
        db.post({ ...newOrder });
      }else{
        const localOrder = docs.find( order => order.reservation_no == i );
        updateDoc(db, localOrder._id, newOrder)
      }
    });

    localIds.map( i => {
      if( !serverIds.includes(i) ){
        const delOrder = docs.find( order => order.reservation_no == i );
        deleteDoc(db, delOrder._id)
      }
    });

    // console.log('result.docs', localIds, serverIds);
  }).catch(error => {
    console.error(error);
  });
};

export const getReservations = async () => {
  const db = new Pouch("reservations");

  return new Promise((resolve, reject) => {
    db.allDocs({
      descending: false,
      include_docs: true,
      attachments: true,
    })
      .then((res) => {
        resolve(res.rows);
      })
      .catch((err) => reject(err));
  });
};

export function deleteDoc(db, documentIdToDelete) {
  return db
    .get(documentIdToDelete)
    .then((doc) => {
      return db.remove(doc);
    })
    .then((result) => {
      // console.log("Document deleted successfully:", result);
    })
    .catch((error) => {
      ErrorHandler.showNotification("Something wents wrong");
      console.error("Error deleting document:", error);
    });
}

export function updateDoc(db, documentIdToUpdate, data) {
  db.get(documentIdToUpdate)
  .then((doc) => {
    return db.put({...doc, ...data});
  } )
}

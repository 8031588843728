import React from "react";
import {
  CloseOutlined,
  FullscreenOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { SideDrawer } from "../SideDrawer";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";

const AppContainer = ({ children }) => {
  const location = useLocation();
  const isSplash = ["/", "/authenticate"].includes( location.pathname );

  return (
    <div className="App">
      <TitleBar />
      <Layout style={{ height: "calc(100vh - 40px)" }}>
        {!isSplash && <SideDrawer />}
        <Layout>{children}</Layout>
      </Layout>
    </div>
  );
};

function minimizeWindow() {
  window.electron.send("minimize-window");
}

function closeWindow() {
  window.electron.send("close-window");
}

export default AppContainer;

const TitleBar = () => {
  return (
    <header className="title-bar items-center shadow-lg border-b border-gray-700">
      <div className="w-40" />
      <div className="title-bar-content text-sm">
        <span className="text-md">Outlet 001</span>
      </div>
      <div className="flex text-base">
        <button onClick={minimizeWindow} className="title-bar-button">
          <MinusOutlined />
        </button>
        <button onClick={minimizeWindow} className="title-bar-button">
          <FullscreenOutlined />
        </button>
        <button onClick={closeWindow} className="title-bar-button">
          <CloseOutlined />
        </button>
      </div>
    </header>
  );
};

import { Button as AntButton } from "antd";
import React from "react";

interface Props {
  title: string;
  className?: string;
  bgColor?: string;
  disabled?: boolean;
  onClick?: () => {} | any;
  loading?: boolean;
}

export const Button = (props: Props) => {
  const {
    title,
    className,
    bgColor = "bg-yellow-600",
    disabled = false,
    onClick = () => {},
    loading = false,
  } = props;
  return (
    <AntButton
      {...props}
      className={`${className} ${
        disabled ? "bg-gray-500" : bgColor
      } rounded-md px-3 mt-2 font-medium justify-center text-white`}
      onClick={onClick}
      loading={loading}
    >
      {title}
    </AntButton>
  );
};

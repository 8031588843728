import moment from "moment";

export function generateTimeSlots() {
  const timeSlots = [];
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set the start time to midnight

  // Generate time slots with a 30-minute gap
  for (let i = 0; i < 24 * 2; i++) {
    const currentTime = new Date(startTime.getTime() + i * 30 * 60 * 1000);
    const formattedTime = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    timeSlots.push(formattedTime);
  }

  return timeSlots;
}

export function roundToNearestHour(date) {
  const roundedDate = new Date(date);
  roundedDate.setMinutes(Math.round(roundedDate.getMinutes() / 30) * 30);
  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);
  return moment(roundedDate).format('hh:mm A');
}

export function onlyKeys(object, keys) {
  return Object.keys(object)
      .filter(function (key) {
          return keys.indexOf(key) >= 0;
      })
      .reduce(function (acc, key) {
          acc[key] = object[key];
          return acc;
      }, {});
}

export function getFirstCharacters(sentence = "") {
  // Split the sentence into words
  const words = sentence?.split(' ');

  // Extract the first character of each word
  const firstCharacters = words.map(word => word.charAt(0));

  return firstCharacters.join('');
}
import React from "react";
import { List, Tooltip } from "antd";
import { CheckOutlined, SyncOutlined } from "@ant-design/icons";
import { PriceComponent } from "../../components/PriceComponent";
import { Badge } from "../../components/Badge";
import moment from "moment";

export const OrderItem = ({ item, index, onClick }: any) => {
  const { doc = {} } = item || {};
  const price = doc?.details?.reduce(
    (a: any, b: any) => a + parseFloat(b.sub_amount),
    0
  );

  return (
    <List.Item className="hover:bg-gray-100" role="button" onClick={onClick}>
      <div className="w-full px-2 border-l-4 border-yellow-700">
        <div className="flex justify-between">
          <span className="font-semibold">
            {doc.order_no} (Guest) <StatusBadge data={doc} />
          </span>
          {!doc.id ? (
            <Tooltip title="Sync" className="text-sm">
              <SyncOutlined />
            </Tooltip>
          ) : (
            <CheckOutlined />
          )}
        </div>
        <div className="flex justify-between mt-1 font-regular">
          <span>
            {doc?.details?.length} Services - <PriceComponent value={price} />
          </span>
          <span className="text-gray-500 text-xs">
            {moment(doc.created_at).format("lll")}
          </span>
        </div>
      </div>
    </List.Item>
  );
};

const StatusBadge = ({ data }: any) => {
  if (data.invalidate_at) {
    return <Badge title="Invalid" type="danger" />;
  }

  if (data.payment) {
    return <Badge title="Paid" type="success" />;
  } else {
    return <Badge title="Unpaid" type="danger" />;
  }
};

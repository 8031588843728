import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ErrorHandler from "../utils/ErrorHandler";

const token = Cookies.get("@token");
axios.defaults.baseURL = "https://ghostemployee.com/lotus-flower-spa-api/public/api/admin";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/admin";
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.timeout = 5000;

// axios.interceptors.request.use(
//   (request) => {
//     console.log(request);
//     // Edit request config
//     return request;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

const baseState: any = {
  token: null,
  user: null,
  setUser: null,
  logout: () => {},
};

const AuthContext = createContext(baseState);

const AuthContextProvider = ({ children }: any) => {
  const [user, setUser]: any = useState<any>();
  const [permissions, setPermissions]: any = useState<any>([]);
  const [users, setUsers]: any = useState<any>([]);
  const [refState, setRefState]: any = useState<any>(); // TO PASS FORM REF BETWEEN COMPONENETS
  const [products, setProducts]: any = useState<any>([]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response?.status === 401 &&
          window.location.hash.indexOf("#/authenticate") === -1
        ) {
          setUser(null);
          Cookies.remove("@token");
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );

    if (token) {
      const cancelTokenSource = axios.CancelToken.source();
      const config = {
        cancelToken: cancelTokenSource.token,
        params: {},
      };

      axios
        .get(`me`, config)
        .then((res) => {
          if (res.status < 300) {
            const { data } = res.data.response;
            onStartSession(data);
          }
        })
        .catch((e) => {
          ErrorHandler.showNotification(e);
        });

      return cancelTokenSource.cancel;
    } else {
      setUser(null);
      if (["#/authenticate", "#/"].includes(window.location.hash)) {
        window.location.href = "/#/authenticate";
      }
    }
  }, []);

  const onStartSession = (data: any) => {
    setUser(data);
    if (data.roles?.length) {
      setPermissions([
        ...data.roles[0].permissions.map((i: any) => i.name),
        ...data.permissions.map((i: any) => i.name),
      ]);
    }
  };

  const logout = () => {
    axios
      .post(`logout`, {
        // device_token: ''
        // udid: ''
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          Cookies.remove("@token");
          setUser(null);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        user,
        setUser,
        onStartSession,
        permissions,
        logout,
        refState,
        setRefState,
        users,
        setUsers,
        products,
        setProducts,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
export default AuthContext;

import React, { forwardRef, useContext, useImperativeHandle } from "react";
import { PriceComponent } from "../../components/PriceComponent";
import { Layout, MenuProps } from "antd";
import { CommentBox } from "../../components/CommentBox";
import { Header } from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import AuthContext from "../../context/AuthContext";

export const OrderView = forwardRef(
  ({ item, withHeader = true, withComments = true, style = {} }: any, ref) => {
    const { user } = useContext(AuthContext);
    const { outlet = {} } = user || {};
    const { doc = {} } = item;

    const onPrint = () => {
      var mywindow = window.open("", "PRINT", "height=1000,width=800");
      if (mywindow) {
        mywindow.document.write("<html><head><title>Order Invoice</title>");
        mywindow.document.write("</head><body>");
        mywindow.document.write(`
      <div style="padding: 20">
        <div style="position: absolute;opacity: 0.3; z-index: -1; color: white;display: flex;justify-content: center; align-items: center;width: 100%; height: 80%;">
          <img src="logo.png" style="width: 400px; height: 400px;" />
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <img src="logo.png" style="width: 140px; height: 140px;" />
            <h1>Lotus Flower Spa (${outlet.name})</h1>
            <small>${outlet.address}</small>
            <p style="margin-top: 20px;">Official Receipt</p>
          </div>
          <div style="text-align: right;">
            <h1>سبا زهرة اللوتس</h1>
            <small>الطابق الثالث، برج 3، خليج الأبراج، حي الدوحة، الدوحة</small>
            <p style="margin-top: 20px;">إيصال رسمي</p>
          </div>
        </div>
      
        <div style="margin: 50px 0;">
          <div>
            <p>Customer: ${doc.name || doc.consumer?.full_name || `Guest`}<br />
            Phone: ${doc.phone || doc.consumer?.phone || `N/A`}<br />
            Reservation Date: ${moment(
              doc.reservation_at || doc.created_at
            ).format("LLL")}<br />
            Created Date: ${moment(doc.created_at).format("LLL")}</p>
          </div>
        </div>

        ${doc.details
          ?.map(
            (i: any) =>
              `<div style="display: flex; justify-content: space-between;">
              <span>${i.name_en} (${i.name_ar})</span>
              <span>QR ${i.unit_price}</span>
            </div>`
          )
          .join("")}
        
        <div
          style="display: flex; justify-content: space-between;border-top: 1px solid black;margin-top: 10px;padding-top: 10px;">
          <span>Sub Total</span>
          <span>QR ${doc.total_amount}</span>
        </div>

        ${
          doc.tip_amount
            ? `<div style="display: flex; justify-content: space-between;padding-top: 10px;">
          <span>Tip (تخفيض)</span>
          <span>QR +${doc.tip_amount}</span>
        </div>`
            : ""
        }

        ${
          doc.discount_amount
            ? `<div style="display: flex; justify-content: space-between;padding-top: 10px;">
          <span>Discount (تخفيض)</span>
          <span>QR -${doc.discount_amount}</span>
        </div>`
            : ""
        }
      
        <div style="display: flex; justify-content: space-between;margin-top: 20px;align-items: end;">
          <div>
            <h2>Total</h2>
            <p>
              Paid QR${doc.net_amount.toFixed(2)} - cash<br />
              ${moment().format("LLL")}<br />
            </p>
          </div>
          <h2>QR ${doc.net_amount.toFixed(2)}</h2>
        </div>

        ${
          doc.therapist
            ? `<div style="display: flex; justify-content: space-between;margin-top: 10px;">
            <h2>Therapist (معالج نفسي)</h2>
            <p>${doc.therapist.full_name}</p>
          </div>`
            : ""
        }

        ${
          doc.creator
            ? `<div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px;">
            <h2>Receptionist (موظف الإستقبال)</h2>
            <p>${doc.creator.full_name}</p>
          </div>`
            : ""
        }

      </div>
      `);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        setTimeout(() => {
          mywindow?.print();
          mywindow?.close();
        }, 500);
      }

      return true;
    };

    useImperativeHandle(ref, () => ({
      onPrint,
    }));

    const items: MenuProps["items"] = [
      {
        label: (
          <>
            <FontAwesomeIcon className="text-gray-600" icon={faPrint} /> Print
          </>
        ),
        key: "0",
        onClick: onPrint,
      },
      {
        label: (
          <>
            <FontAwesomeIcon className="text-gray-600" icon={faEdit} /> Edit
          </>
        ),
        key: "1",
      },
      // {
      //   label: (
      //     <>
      //       <FontAwesomeIcon className="text-gray-600" icon={faCheck} /> Mark as
      //       Paid
      //     </>
      //   ),
      //   key: "2",
      //   onClick: onPaid,
      // },
      // {
      //   type: "divider",
      // },
      // {
      //   label: (
      //     <>
      //       <FontAwesomeIcon className="text-gray-600" icon={faTimes} />{" "}
      //       Invalidate
      //     </>
      //   ),
      //   key: "3",
      //   onClick: onInvalidate,
      // },
    ];

    return (
      <div
        className="relative"
        style={{ height: "calc(100vh - 40px)", overflow: "auto", ...style }}
      >
        {withHeader && (
          <Header title={item.id} desc={doc.name} options={items} />
        )}
        <Layout className="p-10">
          {doc.payment ? (
            <div
              className="absolute text-green-600 bg-green-100 border-green-600 border-2 rounded-lg px-5 py-2 text-xl font-semibold right-20 rotate-45"
              style={{ top: "12%" }}
            >
              PAID
            </div>
          ) : (
            <div
              className="absolute text-red-600 bg-red-100 border-red-600 border-2 rounded-lg px-5 py-2 text-xl font-semibold right-20 rotate-45"
              style={{ top: "12%" }}
            >
              NOT PAID
            </div>
          )}

          <div className="mt-2 font-regular">
            <p>
              Customer:{" "}
              {doc.name || doc.consumer?.full_name || doc.full_name || `Guest`}
            </p>
            <p>
              Phone: {doc.phone || doc.consumer?.phone || doc.phone || `N/A`}
            </p>
            <p>
              Reservation Date:{" "}
              {moment(doc.reservation_at || doc.created_at).format("LLL")}
            </p>
            <p>Created Date: {moment(doc.created_at).format("LLL")}</p>
          </div>
          <div className="mt-2 font-regular">
            <p className="capitalize">
              Creater: {doc.creator?.full_name || `N/A`}
            </p>
            <p className="capitalize">Payment: {doc.payment}</p>
            {/* <p>Trasaction Id (رقم المعاملة): {doc.transaction_id || "--"}</p> */}
          </div>
          <div className="mt-10 font-regular border-t border-t-gray-400">
            {doc.details?.map((i: any) => (
              <div className="flex justify-between my-2">
                <p>
                  {i.name_en} ({i.name_ar}) x{i.quantity}
                </p>
                <PriceComponent value={i.unit_price} />
              </div>
            ))}
          </div>
          <div className="font-regular border-t border-t-gray-400">
            <div className="flex justify-between my-2">
              <p>Sub-Total (المجموع الفرعي)</p>
              <PriceComponent value={doc.total_amount} />
            </div>
            {doc.tip_amount && (
              <div className="flex justify-between my-2">
                <p>Tip (نصيحة)</p>
                <PriceComponent value={`+${doc.tip_amount}`} />
              </div>
            )}
            {doc.discount_amount ? (
              <div className="flex justify-between my-2">
                <p>Discount (تخفيض)</p>
                <PriceComponent value={`-${doc.discount_amount}`} />
              </div>
            ) : (
              false
            )}
          </div>
          <div className="font-regular border-t border-t-gray-400 font-semibold">
            <div className="flex justify-between my-3">
              <p>Total (المجموع)</p>
              <PriceComponent value={doc.net_amount} />
            </div>
          </div>
          {withComments && <CommentBox />}
        </Layout>
      </div>
    );
  }
);

import moment from 'moment';
import React from 'react';

export const CommentBox = () => (
    <div className="mt-20 bg-white border shadow p-5 rounded-md">
      <h1 className="text-base font-medium border-b">Comments</h1>
      <ul className="list-disc">
        <li className="flex justify-between mt-3 text-xs">
          <p>Outlet 1 create a request</p>
          <p>{moment().subtract(1, 'h').fromNow(true)}</p>
        </li>
        <li className="flex justify-between mt-3 text-xs">
          <p>Admin approves the request</p>
          <p>{moment().fromNow(true)}</p>
        </li>
      </ul>
    </div>)
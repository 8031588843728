import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createHashRouter } from "react-router-dom";
import Splash from "./views/Splash";
import AppContainer from "./components/layouts/AppContainer";
import Home from "./views/home";
import Orders from "./views/orders";
import Reservations from "./views/reservations";
import Inventory from "./views/Inventory";
import Settings from "./views/settings";
import Invoices from "./views/invoices";
import Requests from "./views/requests";
import ChangePassword from "./views/settings/ChangePassword";
import Login from "./views/Login";
import { AuthContextProvider } from "./context/AuthContext";
import Users from "./views/users";
import { UserAppointments, UserInfo } from "./views/users/UserView";

// import dayjs from 'dayjs'
// import advancedFormat from 'dayjs/plugin/advancedFormat'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// import localeData from 'dayjs/plugin/localeData'
// import weekday from 'dayjs/plugin/weekday'
// import weekOfYear from 'dayjs/plugin/weekOfYear'
// import weekYear from 'dayjs/plugin/weekYear'

// dayjs.extend(customParseFormat)
// dayjs.extend(advancedFormat)
// dayjs.extend(weekday)
// dayjs.extend(localeData)
// dayjs.extend(weekOfYear)
// dayjs.extend(weekYear)

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderRoute = (Component) => {
  return <AppContainer>{Component}</AppContainer>;
};

const router = createHashRouter([
  {
    path: "/",
    element: renderRoute(<Splash />),
  },
  {
    path: "/authenticate",
    element: renderRoute(<Login />),
  },
  {
    path: "/dashboard",
    element: renderRoute(<Home />),
  },
  {
    path: "/orders/:orderId?",
    element: renderRoute(<Orders />),
  },
  {
    path: "/reservations/:reservationId?",
    element: renderRoute(<Reservations />),
  },
  {
    path: "/inventory/:inventoryId?",
    element: renderRoute(<Inventory />),
  },
  {
    path: "/requests/:requestId?",
    element: renderRoute(<Requests />),
  },
  {
    path: "/invoices/:invoiceId?",
    element: renderRoute(<Invoices />),
  },
  {
    path: "/customers/:customerId?/:slug?",
    element: renderRoute(<Users />)
  },
  {
    path: "/settings/:slug?",
    element: renderRoute(<Settings />),
    children: [
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
]);

root.render(
  <AuthContextProvider>
    <RouterProvider router={router} />
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

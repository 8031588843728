import React, { useEffect, useState } from "react";
import { Button, Layout, Popconfirm, Space, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../components/Header";
import { RequestForm } from "./RequestForm";
import "../../index.css";

const Requests = () => {
  const formRef: any = React.useRef();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getInvoices();
  }, [reload]);

  const getInvoices = async () => {
    setLoading(true);
    axios
      .get("requests")
      .then((res) => {
        setLoading(false);
        if (res.status < 300) setList(res.data.response.data);
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const onDelete = (id: any) => {
    setLoading(true);
    axios
      .delete(`/requests/${id}`)
      .then((res) => {
        setLoading(false);
        if (res.status < 300) setReload(!reload);
        ErrorHandler.showNotification(res);
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const columns = [
    { title: "Request Id", dataIndex: "id" },
    { title: "Product", dataIndex: ["inventory", "name"] },
    { title: "Quantity", dataIndex: "quantity" },
    { title: "Unit", dataIndex: ["inventory", "unit"] },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, row: any) => getApproverStatus(row),
    },
    { title: "Expected Date", dataIndex: "expected_at" },
    { title: "Created At", dataIndex: "created_at" },
    {
      title: "Action",
      render: (_: any, row: any) => (
        <Space>
          {!row.accepted_at && (
            <Tooltip title={"Delete"} placement="bottom">
              <Popconfirm
                title={"Confirmation"}
                description={`Are you sure, you want to delete this record?`}
                onConfirm={() => onDelete(row.id)}
              >
                <DeleteOutlined className="text-red-500" size={18} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        title="Inventory Request"
        desc="You can manage your invoices request from here"
      />
      <div className="p-5">
        <div className="flex justify-end mb-5 gap-2">
          <Button
            loading={loading}
            className="primary-btn"
            onClick={() => formRef.current.open()}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Add Request
          </Button>
        </div>
        <Table columns={columns} dataSource={list} loading={loading} />
        <RequestForm ref={formRef} onComplete={() => setReload(!reload)} />
      </div>
    </Layout>
  );
};

export default Requests;

function getApproverStatus(data: any) {
  if (!data.approved_at && !data.rejected_at) {
    return "Pending";
  } else if (data.approved_at) {
    return "Approved";
  } else {
    return "Rejected";
  }
}

import React from "react";
import { Col, List, Row } from "antd";
import { SettingItem } from "./SettingItem";
import { Outlet, useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  return (
    <Row className="flex-1">
      <Col md={12} lg={6} className="shadow-lg bg-white">
        <h1 className="text-lg font-semibold p-4 border-b-2">Settings</h1>
        <List
          itemLayout="horizontal"
          style={{ overflowY: "auto" }}
          dataSource={ SETTINGS }
          renderItem={(item, index) => (
            <SettingItem {...{ item, index }} onClick={() => navigate(`/settings/${item.link}`)} />
          )}
        />
      </Col>
      <Col md={12} lg={18}>
        <div style={{ height: "calc(100vh - 40px)", overflow: "auto" }}>
          <Outlet />
        </div>
      </Col>
    </Row>
  );
};

export default Settings;

const SETTINGS = [
  {
    label: 'Change Password',
    link: 'change-password'
  }
]
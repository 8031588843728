import React, { useContext, useState } from "react";
import { useDrag } from "react-dnd";
import { SYSTEM } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckDouble,
  faClipboardCheck,
  faSync,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip } from "antd";
import "./style.css";
import AuthContext from "../../context/AuthContext";

const ReservationCard = ({
  event,
  index,
  onClick,
  onUpdate,
  isActive,
}: any) => {

  const {permissions} = useContext(AuthContext);
  const [loading, setLoading] = useState<any>(false);

  const [{ isDragging }, drag] = useDrag({
    type: "DIV_ITEM",
    item: { event },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={`order-card flex flex-col border ${statusStyles(
        event.status
      )} cursor-pointer`}
      style={{
        left: 10 + index * 40,
        height: (SYSTEM.CELL_HEIGHT * event.duration) / 30,
        ...(isDragging && { opacity: 0.5 }),
      }}
    >
      {event.payment ? (
        <span
          className="absolute bg-green-900 text-white px-10 py-1 rotate-45"
          style={{
            top: 5,
            right: -35,
            fontSize: 10,
          }}
        >
          PAID
        </span>
      ) : (
        <span
          className="absolute bg-red-900 text-white px-10 py-1 rotate-45"
          style={{
            top: 12,
            right: -40,
            fontSize: 10,
          }}
        >
          NOT PAID
        </span>
      )}

      <div className="flex flex-col flex-1">
        <p className="text-base font-semibold pr-6 truncate">
          <FontAwesomeIcon
            icon={event.order.doc?.id ? faCheckDouble : faSync}
          />
          &nbsp;
          {event.name}
        </p>
        <p>{event.desc}</p>
      </div>

      <div className="flex justify-between items-center">
        <p className="italic">({STATUS[event.status] || "Pending"})</p>
        {!["cancelled", "in_progress", "closed"].includes(event.status) && permissions.includes('edit_orders') && (
          <div className="flex justify-end gap-2">
            {isActive && (
              <>
                <Tooltip title={"Start"}>
                  <Button
                    loading={loading}
                    className="bg-blue-500 text-white text-sm"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onUpdate(event.id, { status: "in_progress" });
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} size={"sm"} />
                  </Button>
                </Tooltip>
              </>
            )}
            { permissions.includes('cancel_orders') && <Tooltip title={"Cancel"}>
              <Button
                loading={loading}
                className="bg-red-600 text-white text-sm"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdate(event.id, { status: "cancelled" });
                }}
              >
                <FontAwesomeIcon icon={faTimes} size={"sm"} />
              </Button>
            </Tooltip>}
          </div>
        )}

        {event.status == "in_progress" && (
          <Tooltip title={"Close"}>
            <Button
              loading={loading}
              className="bg-green-600 text-white text-sm"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onUpdate(event.id, { status: "closed" });
              }}
            >
              <FontAwesomeIcon icon={faClipboardCheck} size={"sm"} />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ReservationCard;

function statusStyles(status: string) {
  switch (status) {
    case "cancelled":
      return `border-gray-300 text-red-800 bg-red-100`;

    case "in_progress":
      return `border-green-300 text-green-800 bg-green-100`;

    case "closed":
      return `border-blue-200 text-blue-900 bg-blue-100`;

    default:
      return `border-orange-300 text-orange-800 bg-orange-100`;
  }
}

const STATUS: any = {
  pending: "Pending",
  in_progress: "In Progress",
  cancelled: "Cancelled",
  closed: "Closed",
};

import React, { useEffect, useState } from "react";
import { CreditCardFilled, WalletFilled } from "@ant-design/icons";

export const PaymentMethod = ({ onChange }: any) => {

  const [active, setActive] = useState<any>(); 

  useEffect( () => { onChange && onChange(active) }, [active] )

  return (
    <>
      <h1 className="font-bold text-lg mt-3">Payment Method</h1>
      <div className="flex flex-row gap-2">
        {PAYMENT.map((i, index) => (
          <div
            key={i.title}
            role="button"
            className={`my-2 flex flex-col flex-1 justify-center items-center rounded-xl py-2 border ${
              active === i
                ? "text-yellow-600  border-yellow-500 bg-orange-100"
                : "text-gray-500 border-gray-300 bg-gray-100"
            }`}
            onClick={ () => setActive(i) }
          >
            {i.icon}
            <p>{i.title}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export const PAYMENT = [
  {
    title: 'Cash',
    icon: <WalletFilled style={{ fontSize: 20 }} />
  },
  {
    title: 'Credit Card',
    icon: <CreditCardFilled style={{ fontSize: 20 }} />
  },
]
import React, { useEffect, useContext } from "react";
import '../index.css';
import logoImg from '../assets/logo.png'
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Splash = () => {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    useEffect( () => {
        setTimeout(() => {
            navigate( user ? '/dashboard' : '/authenticate');
        }, 3000);
    }, [user])

    return <div className="splash-container w-full">
        <div className="overlay">
            <img src={ logoImg } alt="Logo" className="animate-bounce" />
        </div>
    </div>
}

export default Splash;
import React, { useContext, useEffect, useRef } from "react";
import { generateTimeSlots, roundToNearestHour } from "../../utils/helper";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { EventModel, SYSTEM } from "../../constant";
import ReservationCard from "./ReservationCard";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import ReservationForm from "./ReservationForm";
import Pouch from "pouchdb";
import {
  getReservations,
  getTherapist,
  onDBChange,
  setTherapist,
  syncReservations,
} from "../../utils/dbFunctions";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import AuthContext from "../../context/AuthContext";
import ReservationView from "./ReservationView";
import { TouchBackend } from "react-dnd-touch-backend";

export const USERS = 5;
const db = new Pouch("reservations");
const therapistDB = new Pouch("therapists");

const Reservations = () => {
  let formRef: any = useRef();
  const viewRef: any = useRef();
  let currentRowRef: any = useRef();
  const currentTime: any = roundToNearestHour(new Date());
  const { user, setRefState } = useContext(AuthContext);

  const [users, setUsers] = React.useState<any>([]);
  const [events, setEvents] = React.useState<any>([]);
  const [slots, setSlots] = React.useState<any>([]);
  const [date, setDate] = React.useState<any>(moment().format("ll"));

  useEffect(() => {
    if (navigator.onLine && user) {
      axios
        .get(`users`, {
          params: {
            role: "outlet",
            outlet_id: user.outlet_id,
            designation: "therapist",
          },
        })
        .then((res: any) => {
          if (res.status < 300) {
            const resData = res.data.response.data;
            setTherapist(resData);
          }
        })
        .catch((err) => {
          ErrorHandler.showNotification(err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (navigator.onLine && user) {
      const callInterval = setTimeout(() => {
        axios
          .get(`reservations`, {
            params: {
              outlet_id: user.outlet_id,
              period: [date, date],
            },
          })
          .then((res: any) => {
            if (res.status < 300) {
              const resData = res.data.response.data;
              syncReservations(resData, date);
            }
          })
          .catch((err) => {
            ErrorHandler.showNotification(err);
          });
      }, 1000);

      return () => clearInterval(callInterval);
    }
  }, [user, date]);

  useEffect(() => {
    const reservationChange = onDBChange(db, getData);
    const staffChange = onDBChange(therapistDB, getUsers);
    return () => {
      reservationChange?.cancel();
      staffChange?.cancel();
    };
  }, [date]);

  const getData = () => {
    getReservations().then((res) => {
      setEvents(
        res
          .filter((i: any) =>
            moment(i.doc.reservation_at, "YYYY-MM-DD").isSame(date)
          )
          .map((i: any) => new EventModel(i))
      );
    });
  };

  const getUsers = () => {
    getTherapist().then((res) => setUsers(res));
  };

  useEffect(() => {
    setRefState(formRef);
    setSlots(generateTimeSlots());
  }, [date]);

  useEffect(() => {
    setTimeout(() => {
      if (currentRowRef.current) {
        currentRowRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);
  }, [currentRowRef]);

  const onNext = () => {
    setDate(moment(date).add(1, "day").format("ll"));
  };

  const onPrev = () => {
    setDate(moment(date).subtract(1, "day").format("ll"));
  };

  const onDrop = ({ event, slot, user_id }: any) => {
    if (event.start_time === slot) return; // drop on same cell

    setEvents((prev: any) => {
      const eventIndex = prev.findIndex((i: any) => i.id === event.id);
      prev[eventIndex].start_time = slot;
      prev[eventIndex].user_id = user_id;
      return [...prev];
    });

    const reservation = event.order.doc;
    const values = {
      reservation_at: moment(`${date} ${slot}`).format(SYSTEM.DATE_FORMAT),
      therapist_id: user_id,
    };

    axios({
      url: `reservations/${reservation.id}`,
      method: "PUT",
      data: values,
    })
      .then((res) => {
        if (res.status < 300) {
          ErrorHandler.showNotification(res);
          db.get(event.id).then((doc) => {
            return db.put({ ...doc, ...values });
          });
        }
      })
      .catch((err) => {
        ErrorHandler.showNotification(err);
        return false;
      });
  };

  return (
    <>
      <div className="bg-white h-16 flex justify-center items-center gap-5">
        <FontAwesomeIcon
          icon={faCaretLeft}
          style={{ fontSize: 20, padding: 10 }}
          className="cursor-pointer"
          onClick={onPrev}
        />
        <span className="text-lg">{date}</span>
        <FontAwesomeIcon
          icon={faCaretRight}
          style={{ fontSize: 20, padding: 10 }}
          className="cursor-pointer"
          onClick={onNext}
        />
      </div>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <DndProvider
          backend={TouchBackend}
          options={{
            enableMouseEvents: true,
            enableHoverOutsideTarget: true,
          }}
        >
          <table className="timeline-table">
            <thead>
              <tr className="sticky-head">
                <th style={{ minWidth: 150 }}>{moment(date).format("ll")}</th>
                {users.map((user: any) => {
                  return (
                    <th key={user.id}>
                      <div className="flex flex-row items-center px-3">
                        <img
                          src={require("../../assets/avatar.jpg")}
                          width={50}
                          height={50}
                        />
                        <p className="text-base flex-1 text-left">
                          {user.doc.name}
                        </p>
                        <span className="items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          {
                            events.filter(
                              (event: any) => event.user_id == user.doc.id
                            ).length
                          }
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {slots.map((slot: any) => {
                const isActive: any =
                  moment().format("ll") == moment(date).format("ll") &&
                  currentTime.replace("30", "00") == slot.replace("30", "00");
                const isAfter = moment().isAfter(
                  moment(`${date} ${slot}`, "LL hh:mm A")
                );

                return (
                  <tr
                    ref={isActive ? currentRowRef : null}
                    key={slot}
                    className={`${isAfter ? "bg-slate-200" : ""}`}
                  >
                    {slot.search("30") == -1 ? (
                      <th
                        rowSpan={2}
                        className={`${
                          isActive ? "active-row bg-slate-200 font-bold" : ""
                        } ${isAfter ? "disabled-row" : ""} sticky-left`}
                      >
                        <div className="slot-col">{slot}</div>
                      </th>
                    ) : (
                      false
                    )}
                    {users.map((user: any, i: number) => (
                      <TableCell
                        key={`cell_${i}`}
                        {...{
                          slot,
                          user_id: user.doc.id,
                          events,
                          isActive,
                          isAfter,
                          onDrop,
                          viewRef,
                        }}
                        onClick={(time: string) => {
                          if (isAfter) return;
                          formRef.current.open(
                            moment(`${date} ${time}`, "ll h:mm A"),
                            user.doc.id
                          );
                        }}
                      />
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DndProvider>
        <ReservationView ref={viewRef} />
        <ReservationForm ref={formRef} editableSlot={false} />
      </div>
    </>
  );
};

export default Reservations;

const TableCell = ({
  slot,
  user_id,
  isActive = false,
  isAfter = false,
  onDrop = () => {},
  onClick,
  events,
  viewRef,
}: any) => {
  const [{ isOver }, drop] = useDrop({
    accept: "DIV_ITEM",
    drop: (item: any) => onDrop({ slot, user_id, ...item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <td
      ref={drop}
      onClick={() => onClick(slot)}
      className={
        !isAfter ? "cursor-pointer hover:border-2 hover:bg-slate-100" : ""
      }
      style={{
        height: SYSTEM.CELL_HEIGHT,
        position: "relative",
        background: isOver ? "#00000022" : "",
      }}
    >
      {events
        .filter((event: any) => {
          return (
            roundToNearestHour(new Date(event.order.doc.reservation_at)) ===
              slot && event.user_id === user_id
          );
        })
        .map((event: any, index: number) => (
          <ReservationCard
            {...{ event, index, isActive }}
            key={`reservation_${index}`}
            onUpdate={viewRef.current.onUpdate}
            onClick={() => {
              viewRef.current.open(event.order);
            }}
          />
        ))}
    </td>
  );
};

import React, { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Input, Upload } from "antd";

import {
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";

export const UserForm = React.forwardRef((props: any, ref) => {
  const [form] = useForm<any>();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [image, setImage] = React.useState<any>();
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setLoading(false);
    if (!visible) {
      form.resetFields();
      setImage(undefined)
      setImageUrl(undefined)
    }
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: (values: any = null) => {
      setVisible(true);
      if (values) {
        delete values.logo;
        form.setFieldsValue(values);
        setData(values);
      }
    },
    close: () => setVisible(false),
  }));

  const onSubmit = (values: any) => {
    setLoading(true);
    values.phone = values.phone?.toString();
    values.alt_phone = values.alt_phone?.toString();
    values.role = 'consumer';

    var formData = new FormData();
    formData.append("_method", values.id ? "PUT" : "POST");
    image && formData.append("profile_image", image);
    for (var key in values) {
      formData.append(key, values[key] || "");
    }

    axios
      .post(values.id ? `/users/${values.id}` : `users`, formData)
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setData(res.data.response.data);
          ErrorHandler.showNotification(res);
          setVisible(false);
          props.onComplete && props.onComplete();
        }
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };


  const handleUploadChange = (info: any) => {
    const { file } = info;
    setImage(file);

    if (file.type.startsWith("image/")) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadButton = () => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: "100%", borderRadius: "50%" }}
        />
      );
    }
    return (
      <button style={{ border: 0, background: "none" }} type="button">
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
    );
  };

  return (
    <Modal
      title={"Add Customer"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okButtonProps={{ className: "submit-btn" }}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        className="mt-10"
        autoComplete="off"
      >
        <div className="text-center">
        <Upload
          name="image"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleUploadChange}
        >
          {data?.profile_image_url ? (
            <img src={data?.profile_image_url} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton()
          )}
        </Upload>
        </div>

        <Form.Item label="Hidden Field" name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Full Name"
          name={"full_name"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Input className="w-full" placeholder="Enter outlet name" />
        </Form.Item>
        <Form.Item label="Phone" name={"phone"}>
          <InputNumber
            addonBefore={"+971"}
            className="w-full"
            placeholder="Enter phone number"
          />
        </Form.Item>

        <Form.Item label="Alt Phone" name={"alt_phone"}>
          <InputNumber
            addonBefore={"+971"}
            className="w-full"
            placeholder="Enter alt phone"
          />
        </Form.Item>

        <Form.Item label="Address" name={"address"}>
          <Input className="w-full" placeholder="Enter address" />
        </Form.Item>
      </Form>
    </Modal>
  );
});

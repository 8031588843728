import React, {useEffect, useState} from "react";
import { Col, Input, List, Row } from "antd";
import Pouch from 'pouchdb';

import { OrderItem } from "./OrderItem";
import { useNavigate, useParams } from "react-router-dom";
import { OrderView } from "./OrderView";
import { Header } from "../../components/Header";

const Orders = () => {
  const db = new Pouch('orders');
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrders();
    const changeListener = db.changes({
      live: true,
      since: 'now',
    }).on('change', function (change) {
      getOrders()
    }).on('error', function (error) {
      console.error('Change listener error:', error);
    });

    return () => {
      changeListener?.cancel();
    };
  }, [])

  const getOrders = () => {
    db.allDocs({
      descending: true,
      include_docs: true,
      attachments: true,
    }).then((res: any) => {
      setOrders(res.rows);
    })
  }

  return (
    <Row className="flex-1">
      <Col md={12} lg={8} className="shadow-lg bg-white border-r flex flex-col" style={{height: 'calc(100vh - 40px)', overflowY: 'scroll'}}>
        <Header title={'Orders'} children={<div className="mt-2">
          <Input.Search
          addonBefore="Order Id"
          placeholder="Search by order id or customer name"
          allowClear
          onSearch={() => {}}
          style={{ width: '100%' }}
        />
        </div>} />
        <List
          itemLayout="horizontal"
          style={{ flex: 1, overflow: "auto" }}
          dataSource={ orders }
          renderItem={(item: any, index) => (
            <OrderItem {...{ item, index }} onClick={() => navigate(`/orders/${ item.id }`)} />
          )}
        />
      </Col>
      <Col md={12} lg={16}>
        { (orders.length && orderId) ? <OrderView item={ orders.find( (i: any) => i.id === orderId ) } /> : <></> }
      </Col>
    </Row>
  );
};

export default Orders;

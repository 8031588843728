import React from "react";
import { List } from "antd";

export const SettingItem = ({ item, onClick }: any) => {
  return (
    <List.Item className="hover:bg-gray-100" role="button" onClick={onClick}>
        <div className="w-full px-2 border-l-4 border-yellow-700">
          <div className="flex justify-between">
            <span className="font-semibold">{item.label}</span>
          </div>
        </div>
    </List.Item>
  );
};

import React, { forwardRef, useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { SYSTEM } from "../../constant";
import moment from "moment";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import "../../index.css";
import dayjs from "dayjs";

export const InvoiceForm = forwardRef((props: any, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    !visible && form.resetFields();
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: (data: any = null) => {
      setVisible(true);
      if (data) {
        data.due_date = dayjs(data.due_date);
        form.setFieldsValue(data);
        setFileList(data.multimedia);
      }
    },
    close: () => setVisible(false),
  }));

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onSubmit = (values: any) => {
      values.due_date = values.due_date
        ? moment(values.due_date).format(SYSTEM.DATE_FORMAT)
        : "";

      var formData = new FormData();
      values.id && formData.append('_method', 'PATCH');
      for (var key in values) {
        if (key == "attachments" && values[key]) {
          values[key].forEach((file: any, index: number) => {
            formData.append(`attachments[${index}]`, file.originFileObj);
          });
        } else {
          formData.append(key, values[key] || "");
        }
      }

      setLoading(true);
      axios
        .post( values.id ? `invoices/${values.id}` : `invoices`, formData)
        .then((res) => {
          setLoading(false);
          if (res.status < 300) {
            setVisible(false);
            form.resetFields();
            ErrorHandler.showNotification(res.data.response.messages[0]);
            props.onComplete() 
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
        });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      onOk={() => form.submit()}
      okButtonProps={{ className: "submit-btn" }}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        className="text-sm"
      >
        <Form.Item name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        <Form.Item label="Refrence Number" name={"invoice_no"}>
          <Input placeholder="Refrence Number" />
        </Form.Item>
        <Form.Item
          label="Vendor"
          name={"vendor"}
          rules={[{ required: true, message: "Please input your name" }]}
        >
          <Input placeholder="Vendor Name" />
        </Form.Item>
        <Form.Item label="Due Date" name={"due_date"}>
          <DatePicker placeholder="Due Date" />
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col lg={12}>
            <Form.Item
              label="Invoice Total"
              name={"total_amount"}
              rules={[{ required: true, message: "Please input your name" }]}
            >
              <InputNumber placeholder="Total Amount" className="w-full" />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Total Paid"
              name={"total_paid"}
              rules={[{ required: true, message: "Please input your name" }]}
            >
              <InputNumber placeholder="Total Paid" className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        {fileList.map((file: any) => (
          <Image
            key={file.id}
            src={file.url}
            alt={file.name}
            className="border rounded"
            style={{ width: 100, height: 100 }}
          />
        ))}

        <Form.Item
          label="Upload"
          name={"attachments"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            listType="picture-card"
            beforeUpload={() => false}
            accept="image/*"
            maxCount={5}
            multiple
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
});

import React, { forwardRef, useContext, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { SYSTEM } from "../../constant";
import moment from "moment";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import "../../index.css";
import dayjs from "dayjs";
import AuthContext from "../../context/AuthContext";

export const RequestForm = forwardRef((props: any, ref) => {
  const { user } = useContext(AuthContext);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {

    if (user) {
      setLoading(true);
      axios
        .get(`/inventories`)
        .then((res) => {
          setLoading(false);
          if (res.status < 300) {
            setProducts(res.data.response.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
        });
    }
    
  }, [user])

  useEffect(() => {
    !visible && form.resetFields();
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: (data: any = null) => {
      setVisible(true);
      if (data) {
        data.expected_at = dayjs(data.expected_at);
        form.setFieldsValue(data);
      }
    },
    close: () => setVisible(false),
  }));

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onSubmit = (values: any) => {
      values.expected_at = values.expected_at
        ? moment(values.expected_at).format(SYSTEM.DATE_FORMAT)
        : "";

      setLoading(true);
      axios
        .post( values.id ? `requests/${values.id}` : `requests`, values)
        .then((res) => {
          setLoading(false);
          if (res.status < 300) {
            setVisible(false);
            form.resetFields();
            ErrorHandler.showNotification(res.data.response.messages[0]);
            props.onComplete() 
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
        });
  };

  return (
    <Modal
      title={"Inventory Request"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      onOk={() => form.submit()}
      okButtonProps={{className: 'submit-btn'}}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        className="mt-10"
      >
        <Form.Item
          label="Expected Date"
          name={"expected_at"}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="center">
                  <Form.Item
                    {...field}
                    label="Item"
                    name={[field.name, "inventory_id"]}
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Select style={{ width: 150 }} showSearch>
                      {products.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Quantity"
                    name={[field.name, "quantity"]}
                    rules={[{ required: true, message: "This field is required" }]}
                    initialValue={1}
                  >
                    <InputNumber style={{ width: 150 }} />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                    className="text-red-500"
                  />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
});

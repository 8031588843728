import React from "react";

const ProductItem = ({ data = {}, onAdd = () => {} }: any) => {
  return (
    <div
      className="border p-3 rounded-xl bg-white shadow-sm"
      role="button"
      onClick={() => onAdd(data)}
    >
      <div className="">
        <h2 className="font-semibold">{data.name_en}</h2>
        <h2 className="font-semibold text-right">{data.name_ar}</h2>
      </div>
      <div className="flex justify-between">
        <p className="text-sm">{data.description_en}</p>
        <p className="font-bold">{data.price} QR</p>
      </div>
    </div>
  );
};

export default ProductItem;

import { faBox, faBoxOpen, faPaperPlane, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export const SYSTEM = {
  LEFT_CURRENCY: '',
  RIGHT_CURRENCY: 'QR',
  CELL_HEIGHT: 60,
  DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss'
}

export const IMAGES = {
  logo: '../public/logo.png'
}
export class EventModel {
  constructor(data){
    return ({
      id: data.id,
      name: data.doc.consumer?.full_name || data.doc?.full_name,
      date: moment(data.doc.reservation_at, SYSTEM.DATE_FORMAT).format('LL'),
      start_time: moment(data.doc.reservation_at, SYSTEM.DATE_FORMAT).format('hh:mm A'),
      desc: data.doc.details?.map( (item) => item.name_en ).join(', '),
      duration: (data.doc.details?.length || 0) * 60,
      color: "red",
      user_id: data.doc.therapist_id,
      payment: data.doc.payment,
      status: data.doc.status,
      order: data
    })
  }
}

export class DBTherapistModel {
  constructor(data){
    return ({
      id: data.id,
      name: data.full_name,
      created_at: data.created_at,
    })
  }
}

export const PAYMENTS = [
  {
    label: "Card",
    value: "card",
  },
  {
    label: "Cash",
    value: "cash",
  },
];

export const STATUS = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];
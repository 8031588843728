import React from "react";
import { Button, Form, Input, Layout } from "antd";

const ChangePassword = () => {
  return (
    <Layout className="p-5">
      <h1 className="text-xl font-semibold text-center">Change Password</h1>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-start-2 col-span-4 bg-white rounded-lg shadow mt-10">
          <Form
            layout={"vertical"}
            // onFinish={onSubmit}
            className="p-10"
          >
            <Form.Item 
              label={'Current Password'} 
              name={'password'}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input.Password
                placeholder="Current Password"
              />
            </Form.Item>
            <Form.Item 
              label={'New Password'} 
              name={'new_password'}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input.Password
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item 
              label={'Confirm Password'} 
              name={"confirm_password"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input.Password
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Button className="bg-yellow-500 text-white">Submit</Button>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;

import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Collapse,
  List,
  Row,
  Space,
} from "antd";
import { useRef } from "react";
import { BillContainer } from "./BillContainer";
import CategoryWithProduct from "../../components/CategoryWithProduct";
import { useLocation } from "react-router-dom";
import Pouch from 'pouchdb';
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { dbTruncate } from "../../utils/dbFunctions";

const db = new Pouch('services');

const Home = () => {
  
  const scrollRef: any = useRef();
  const { state } = useLocation();
  const [items, setItems] = useState<any>([]);
  const [list, setList] = useState<any>([]);

  useEffect(() => {

    if( navigator.onLine ){
      axios.get(`outlet/services`)
      .then((res: any) => {
        if(res.status < 300){
          const resData = res.data.response.data;
          dbTruncate(db)
          .then((res: any) => {
            db.bulkDocs(resData);
          })
        }
      }).catch(err => {
        ErrorHandler.showNotification(err);
      })
    }

    getServices()
    const changeListener = db.changes({
      live: true,
      since: 'now',
    }).on('change', function (change) {
      getServices()
    }).on('error', function (error) {
      console.error('Change listener error:', error);
    });
    return () => {
      changeListener?.cancel();
    };
  }, [])

  React.useEffect(() => {
    if (state?.reservation) {
      setItems(state.reservation.items);
    }
  }, [state]);
  
  const getServices = () => {
    db.allDocs({
      descending: false,
      include_docs: true,
      attachments: true,
    }).then((res: any) => {
      setList(res.rows);
    })
  }

  const onAdd = (service: any) => {
    const itemIndex = items.findIndex((i: any) => i.id == service.id);

    if (itemIndex != -1) {
      items[itemIndex].quantity += 1;
      setItems([...items]);
    } else {
      setItems([
        ...items,
        {
          ...service,
          quantity: 1,
        },
      ]);
    }
  };

  return (
    <Row>
      <Col sm={12} lg={16}>
        <List
          style={{ height: "calc(100vh - 40px)", overflow: "auto" }}
          itemLayout="horizontal"
          header={
            <>
              {/* <NotificationAlerts /> */}
              <div className="flex px-5 py-1 gap-2 flex-wrap">
                {list.map((item: any) => (
                  <button
                    key={item.id}
                    className="py-1 px-3 border border-gray-400 rounded-xl"
                    onClick={() =>
                      scrollRef[item.doc.id].scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    {item.doc.name_en}
                  </button>
                ))}
              </div>
            </>
          }
          dataSource={list}
          renderItem={(item: any, index) => (
            <List.Item ref={(e) => (scrollRef[item.doc.id] = e)} key={index}>
              <CategoryWithProduct item={item.doc} onAdd={onAdd} />
            </List.Item>
          )}
        />
      </Col>
      <Col sm={12} lg={8} className="flex">
        <BillContainer items={items} setItems={setItems} />
      </Col>
    </Row>
  );
};

export default Home;

const NotificationAlerts = () => (
  <Collapse
    className="rounded-none"
    size="small"
    items={[
      {
        key: "1",
        label: (
          <div className="flex flex-row gap-2">
            <Badge count={2} color="#1677FF" />
            <p>Upcoming Reservation | </p>
            <Badge count={4} color="#faad14" />
            <p>Pending Resevations | </p>
            <Badge count={2} />
            <p>Inventory Alerts</p>
          </div>
        ),
        children: (
          <div className="flex flex-col gap-2">
            <Alert
              banner
              closable
              type="info"
              message={`Reservation - Natly has a reservation after 2 hours`}
            />
            <Alert
              banner
              closable
              type="error"
              message={`Inventory - Hot Wax is going to end soon`}
              action={
                <Space direction="vertical">
                  <Button size="small" type="primary">
                    Request
                  </Button>
                </Space>
              }
            />
            <Alert
              banner
              closable
              type="warning"
              message={`New Reservation Request from Natly`}
              action={
                <Space direction="horizontal">
                  <Button size="small" type="primary">
                    Accept
                  </Button>
                  <Button size="small" danger ghost>
                    Decline
                  </Button>
                </Space>
              }
            />
          </div>
        ),
      },
    ]}
  />
);
import React, { useCallback, useContext, useEffect } from "react";
import {
  Modal,
  Form,
  DatePicker,
  Select,
  InputNumber,
  Button,
  Input,
} from "antd";
import Pouch from "pouchdb";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { SYSTEM } from "../../constant";
import { getServices, getTherapist } from "../../utils/dbFunctions";
import axios from "axios";
import { UserSelector } from "../../components/UserSelector";
import ErrorHandler from "../../utils/ErrorHandler";

import dayjs from "dayjs";
import AuthContext from "../../context/AuthContext";

const db = new Pouch("reservations");
const ReservationForm = React.forwardRef((props: any, ref) => {

  const {
    editableSlot = true
  } = props

  const {permissions} = useContext(AuthContext);

  const [form] = useForm<any>();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [services, setServices] = React.useState([]);

  const [reservation, setReservation] = React.useState<any>();
  const [total, setTotal] = React.useState<any>(0);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle initialValue={"%"}>
      <Select style={{ width: 70 }} defaultValue={"%"}>
        <Select.Option value="-">-</Select.Option>
        <Select.Option value="%">%</Select.Option>
      </Select>
    </Form.Item>
  );

  React.useImperativeHandle(ref, () => ({
    open: (datetime: any, user_id: string) => {
      setVisible(true);
      form.setFieldValue(
        "reservation_at",
        dayjs(datetime.format(SYSTEM.DATE_FORMAT))
      );
      form.setFieldValue("therapist_id", user_id);
    },
    close: () => setVisible(false),
    update: (data: any) => {

      setVisible(true);
      data?.id && setReservation(data);
      form.setFieldsValue({
        therapist_id: data.therapist_id,
        consumer_id: data.consumer_id,
        payment: data.payment,
        discount: data.discount_amount,
        status: data.status,
        reservation_at: dayjs(data.reservation_at),
        details: data.details.map((i: any) => ({
          service_id: i.service_id,
          quantity: i.quantity,
        })),
      });
    },
  }));

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setReservation(undefined);
    } else {
      getServices().then((res) => setServices(res));
      getTherapist().then((res) => setUsers(res));
    }
  }, [visible]);

  const onSubmit = async (values: any) => {
    values = onCalculate(values);
    if (navigator.onLine) {
      setLoading(true);
      values = await axios(
        reservation
          ? {
              url: `reservations/${reservation.id}`,
              method: "PUT",
              data: values,
            }
          : {
              url: `reservations`,
              method: "POST",
              data: values,
            }
      )
        .then((res) => {
          setLoading(false);
          if (res.status < 300) {
            ErrorHandler.showNotification(res);
            return res.data.response.data;
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
          return false;
        });
    }

    if (values) {
      if (reservation) {
        db.get(reservation._id).then((doc) => {
          return db.put({ ...doc, ...values });
        });
      } else {
        db.post({ ...values });
      }
      setVisible(false);
      form.resetFields();
    }
  };

  const onCalculate = (values: any) => {
    const serviceFlat: any = services.map((i: any) => i.doc.services).flat(1);

    values.reservation_no = new Date().getTime();
    values.reservation_at = values.reservation_at.format(SYSTEM.DATE_FORMAT);
    values.details =
      values.details?.map((item: any) => {
        const service: any = serviceFlat.find(
          (i: any) => i.id == item.service_id
        );
        return {
          ...item,
          name_en: service.name_en,
          name_ar: service.name_ar,
          unit_price: service.price,
          sub_amount: parseFloat(service.price) * parseInt(item.quantity),
        };
      }) || [];

    const totalAmount = values.details.reduce(
      (a: number, b: any) => a + b.sub_amount,
      0
    );

    if (values.discount) {
      switch (values.prefix) {
        case "%":
          values.discount_amount = (totalAmount * values.discount) / 100;
          break;

        default:
          values.discount_amount = totalAmount - values.discount;
          break;
      }
    }

    values.net_amount = totalAmount - parseInt(values.discount_amount || 0) + parseInt(values.tip_amount || 0);
    return values;
  };

  const onChange = () => {
    const values = onCalculate( form.getFieldsValue() );
    setTotal(values.net_amount)
  }

  return (
    <Modal
      title={"Order or Reservation"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      onOk={() => form.submit()}
      okButtonProps={{ className: "submit-btn" }}
      confirmLoading={loading}
      style={{ zIndex: 1000 }}
      width={"60%"}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        onChange={onChange}
        className="mt-10 grid grid-cols-2 gap-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <Form.Item
            label="Hidden Field"
            name="full_name"
            style={{ display: "none" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Hidden Field"
            name="phone"
            style={{ display: "none" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Customer"
            name={"consumer_id"}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <UserSelector
              className="w-full"
              onAddUser={(values: any) => form.setFieldsValue(values)}
            />
          </Form.Item>
          <Form.Item
            label="Reservation Datetime"
            name={"reservation_at"}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <DatePicker showTime={{minuteStep: 30}} className="w-full" disabled={!editableSlot} showSecond={false} />
          </Form.Item>
          <Form.Item
            label="Therapist"
            name={"therapist_id"}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <Select showSearch>
              {users.map((i: any) => (
                <Select.Option value={i.doc.id}>{i.doc.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item label="Status" name={"status"} initialValue={'pending'}>
            <Select
              allowClear
              options={[
                {
                  label: "Pending",
                  value: "pending",
                },
                {
                  label: "In Progress",
                  value: "in_progress",
                },
                {
                  label: "Closed",
                  value: "closed",
                },
                ...( permissions.includes('cancel_orders') ? [{
                  label: "Cancelled",
                  value: "cancelled",
                }] : [] ),
              ]}
            />
          </Form.Item>
          <Form.Item label="Payment Via" name={"payment"}>
            <Select
              allowClear
              options={[
                {
                  label: "Credit Card",
                  value: "card",
                },
                {
                  label: "Cash",
                  value: "cash",
                },
              ]}
            />
          </Form.Item>
        </div>

        <div>
          <div className="grid grid-cols-2 gap-3">
          { permissions.includes('can_add_discount') && <Form.Item label="Discount" name={"discount"}>
            <Input addonBefore={prefixSelector} className="w-full" />
          </Form.Item> }
          <Form.Item label="Tip" name={"tip_amount"} initialValue={0}>
            <Input className="w-full" />
          </Form.Item>
          </div>
          <Form.List name="details">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div className="flex gap-2" key={`details_${index}`}>
                    <Form.Item
                      {...field}
                      style={{ flex: 1 }}
                      label="Item"
                      name={[field.name, "service_id"]}
                      rules={[{ required: true, message: "Missing item" }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        style={{ width: "100%" }}
                        onChange={onChange}
                        options={services.map((group: any) => ({
                          label: group.doc.name_en,
                          options: group.doc.services.map((service: any) => ({
                            label: `${service.name_en} (${service.price} ${SYSTEM.RIGHT_CURRENCY})`,
                            value: service.id,
                          })),
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Quantity"
                      name={[field.name, "quantity"]}
                      rules={[{ required: true, message: "Missing price" }]}
                      initialValue={1}
                    >
                      <InputNumber style={{ width: 100 }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        onChange()
                      }}
                      className="text-red-500"
                    />
                  </div>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Services
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className={'flex justify-between text-lg font-semibold'}>
            <p>Total</p>
            <p>{!isNaN(total) ? total : 0} QR</p>
          </div>
        </div>
      </Form>
    </Modal>
  );
});

export default ReservationForm;

import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPrint, faSync } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Pouch from "pouchdb";
import axios from "axios";
import { deleteDoc } from "../../utils/dbFunctions";
import ErrorHandler from "../../utils/ErrorHandler";
import { Button, Modal, Popconfirm, Spin, Tabs, Timeline } from "antd";
import { OrderView } from "../orders/OrderView";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import AuthContext from "../../context/AuthContext";
import { TooltipButton } from "../../components/TooltipButton";
import moment from "moment";
import { ArrowRightOutlined } from "@ant-design/icons";

const db = new Pouch("reservations");
const ReservationView = React.forwardRef((props, ref) => {
  const orderRef: any = useRef();
  const { refState, permissions } = useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<any>();

  React.useImperativeHandle(ref, () => ({
    open: (order: any) => {
      setVisible(true);
      setData({ ...order });
    },
    close: () => setVisible(false),
    onUpdate,
  }));

  const onSync = () => {
    db.get(data.doc._id).then((doc) => {
      setLoading(true);
      return axios
        .post(`reservations`, data.doc)
        .then((res) => {
          setLoading(false);
          return db.put({ ...doc, ...res.data.response.data });
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
        });
    });
  };

  const onUpdate = (id: any, values: any) => {
    db.get(id).then((doc: any) => {
      setLoading(true);
      return axios
        .post(`reservations/${doc.id}`, {
          _method: "PATCH",
          ...values,
        })
        .then((res) => {
          setLoading(false);
          // console.log(res);
          return db.put({ ...doc, ...res.data.response.data });
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
        });
    });
  };

  const onDelete = () => {
    setLoading(true);
    return axios
      .delete(`reservations/${data?.doc?.id}`)
      .then((res) => {
        setLoading(false);
        deleteDoc(db, data.id);
        setVisible(!visible);
        ErrorHandler.showNotification("Deleted Successfully");
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const OrderInfo = () => {
    return (
      <div className="flex flex-row gap-2" onClick={(e) => e.stopPropagation()}>
        <div className="flex-1">
          {data && (
            <OrderView
              ref={orderRef}
              item={data}
              withHeader={false}
              withComments={false}
              style={{ height: "auto" }}
            />
          )}
        </div>
        <div className="flex flex-col gap-1">
          {!data?.doc?.id && (
            <Button onClick={onSync} loading={loading}>
              <FontAwesomeIcon icon={faSync} />
            </Button>
          )}
          { data?.doc?.payment && <TooltipButton
            title={"Print"}
            className="bg-gray-500 text-white"
            onClick={() => orderRef.current.onPrint()}
            loading={loading}
            icon={faPrint}
          />}
          {data?.doc?.status != "closed" && (
            <>
              {permissions.includes("edit_orders") && (
                <TooltipButton
                  title={"Edit"}
                  className="bg-blue-900 text-white"
                  onClick={() => {
                    setVisible(false);
                    refState.current.update(data.doc);
                  }}
                  loading={loading}
                  icon={faEdit}
                />
              )}
              {permissions.includes("delete_orders") && (
                <Popconfirm
                  title={"Confirmation"}
                  okButtonProps={{ className: "submit-btn" }}
                  onConfirm={onDelete}
                >
                  <TooltipButton
                    title={"Delete"}
                    className="bg-red-600 text-white"
                    loading={loading}
                    icon={faTrashAlt}
                  />
                </Popconfirm>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={"Orders"}
      open={visible}
      onCancel={(e) => {
        e.stopPropagation();
        setVisible(!visible);
      }}
      footer={false}
      width={800}
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Info",
            key: "1",
            children: <OrderInfo />,
          },
          {
            label: "History",
            key: "2",
            destroyInactiveTabPane: true,
            children: <History orderId={data?.doc?.id} />,
          },
          {
            label: "Payment",
            key: "3",
            children: <></>,
          },
        ]}
      />
    </Modal>
  );
});

export default ReservationView;

const History = ({ orderId }: any) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setLoading(true);
    setHistory([]);
    axios
      .get(`reservations/${orderId}/history`)
      .then((res) => {
        setLoading(false);
        const logs = res.data.response.data;
        setHistory(
          logs.map((i: any) => {
            const { attributes = {}, old = {} } = i.properties;
            return {
              label: <p className="capitalize">{i.event}</p>,
              children: (
                <>
                  <p className="font-semibold">{i.causer?.full_name}</p>
                  <p className="">( {moment(i.created_at).format("LLL")} )</p>
                  {i.event === "updated" &&
                    Object.keys(attributes).map((key) => {
                      if (old[key] !== attributes[key]) {
                        return (
                          <p className="capitalize">
                            {key.replaceAll("_", " ")}: <br />
                            {old[key] || "N/A"}&emsp;
                            <ArrowRightOutlined />
                            &emsp;{attributes[key]}
                          </p>
                        );
                      }
                    })}
                </>
              ),
            };
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  }, [orderId]);

  return (
    <Spin spinning={loading}>
      <Timeline mode={"left"} items={history} />
    </Spin>
  );
};

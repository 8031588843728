import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ErrorHandler from "../utils/ErrorHandler";
import AuthContext from "../context/AuthContext";

export const UserSelector = ({ cache = true, ...props }: any) => {
  const [form] = useForm<any>();
  const { users, setUsers } = useContext(AuthContext);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cache || !users.length) {
      setLoading(true);
      axios
        .get(`users`, {
          params: {
            role: "consumer",
            minimize: true,
          },
        })
        .then((res: any) => {
          setLoading(false);
          if ("response" in res.data) {
            setUsers([...res.data.response.data]);
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
          return false;
        });
    }
  }, []);

  return (
    <>
      <Select
        {...props}
        loading={loading}
        placeholder="Search customer here..."
        showSearch
        allowClear
        virtual
        optionFilterProp="label"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px",
              }}
            >
              <Button
                type="primary"
                onClick={() => setVisible(true)}
                className="submit-btn"
              >
                Add User
              </Button>
            </div>
          </>
        )}
      >
        {users.map((option: any) => (
          <Select.Option
            key={option.id}
            value={option.id}
            label={option.full_name}
          >
            {option.full_name}
          </Select.Option>
        ))}
      </Select>

      <Modal
        title={"Add Customer"}
        open={visible}
        onCancel={() => setVisible(!visible)}
        okText={"Submit"}
        onOk={() => form.submit()}
        okButtonProps={{ className: "submit-btn" }}
      >
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(values: any) => {
            props.onAddUser({ ...values, consumer_id: 0 });
            setUsers([
              ...users,
              {
                id: 0,
                ...values,
              },
            ]);
            setVisible(false);
            form.resetFields();
          }}
          className="mt-10"
        >
          <Form.Item
            label="Customer Name"
            name={"full_name"}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Customer Phone"
            name={"phone"}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <Input className="w-full" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

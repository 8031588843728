import React, { useContext, useState } from "react";
import "../index.css";
import logoImg from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { Checkbox, Form, Input } from "antd";
import { Button } from "../components/Button";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import Cookies from "js-cookie";
import AuthContext from "../context/AuthContext";
import ErrorHandler from "../utils/ErrorHandler";

const Login = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { onStartSession } = useContext(AuthContext);
  const [ loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    axios.post('/login', values)
    .then( res => {
      setLoading(false);
      if(res.status === 200){
        const userData = res.data.response.data;  
        onStartSession(userData);
        Cookies.set('@token', userData.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
        navigate('/dashboard')
      }
    } ).catch(err => {
      setLoading(false);
      ErrorHandler.showNotification(err);
    })
  }

  return (
    <div className="splash-container w-full">
      <div className="overlay">
        <div className="bg-white p-5 sm:w-1/2 lg:w-1/3 xl:w-1/4 rounded-xl">
          <div className="flex flex-1 justify-center">
            <img src={logoImg} alt="Logo" style={{ width: 150, height: 150 }} />
          </div>
          <Form
            ref={form}
            layout="vertical"
            onFinish={onSubmit}
            className="mt-10"
            autoComplete={false}
          >
            <Form.Item
              className="mb-3"
              label={"Email"}
              name={'email'}
              autoComplete={false}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input placeholder="Enter your email address or username" />
            </Form.Item>
            <Form.Item
              className="mb-3"
              name={'password'}
              label={"Password"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input.Password placeholder="*********" />
            </Form.Item>
            <Form.Item>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Button title="LOG IN" className="float-right" loading={loading} htmlType={'submit'} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;

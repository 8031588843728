import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "antd";

export const TooltipButton = ({ loading, onClick, title, className, icon }: any) => {
  return (
    <Tooltip title={title} placement="left">
      <Button {...{ onClick, loading, className }}>
        <FontAwesomeIcon icon={icon} />
      </Button>
    </Tooltip>
  );
};

import { Select } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ErrorHandler from "../utils/ErrorHandler";
import AuthContext from "../context/AuthContext";

export const ProductSelector = ({ cache = true, ...props }: any) => {
  const { products, setProducts } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cache || !products.length) {
      setLoading(true);
      axios
        .get(`inventories`)
        .then((res: any) => {
          setLoading(false);
          if ("response" in res.data) {
            setProducts([...res.data.response.data]);
          }
        })
        .catch((err) => {
          setLoading(false);
          ErrorHandler.showNotification(err);
          return false;
        });
    }
  }, []);

  return (
    <Select
      {...props}
      loading={loading}
      placeholder="Select a product"
      showSearch
      allowClear
      virtual
      optionFilterProp="label"
    >
      {products.map((option: any) => (
        <Select.Option key={option.id} value={option.id} label={option.name}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};

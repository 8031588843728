import React from "react";
import ProductItem from "./ProductItem";
import { Col, Row } from "antd";

const CategoryWithProduct = ({ item = {}, columns = 2, onAdd = () => {} }: any) => {
  return (
    <div className="p-5 flex-1">
      <h2 className="font-semibold mb-4">{item.name_en}</h2>
      <Row gutter={[8, 8]}>
        {item.services?.map((product: any) => (
          <Col span={24/columns} key={product.id}>
            <ProductItem data={product} onAdd={onAdd} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryWithProduct;

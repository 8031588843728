import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../components/Header";
import { InvoiceForm } from "./InvoiceForm";
import "../../index.css";
import { SYSTEM } from "../../constant";

const Invoices = () => {
  const formRef: any = React.useRef();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getInvoices();
  }, [reload]);

  const getInvoices = async (params = {}) => {
    setLoading(true);
    axios
      .get("invoices", { params })
      .then((res) => {
        setLoading(false);
        if (res.status < 300) setList(res.data.response.data);
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const onDelete = (id: any) => {
    setLoading(true);
    axios
      .delete(`/invoices/${id}`)
      .then((res) => {
        setLoading(false);
        if (res.status < 300) setReload(!reload);
        ErrorHandler.showNotification(res);
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const columns = [
    { title: "S.no", render: (_: any, __: any, index: any) => index + 1 },
    { title: "Invoice No", dataIndex: "invoice_no" },
    { title: "Receptionist", dataIndex: ["user", "full_name"] },
    { title: "Vendor", dataIndex: "vendor" },
    { title: "Net Amount (QR)", dataIndex: "total_amount" },
    { title: "Paid Amount (QR)", dataIndex: "total_paid" },
    {
      title: "Outstanding Amount (QR)",
      dataIndex: "total_paid",
      render: (_: any, row: any) => row.total_amount - row.total_paid,
    },
    { title: "Due Date", dataIndex: "due_date" },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, row: any) => getApproverStatus(row),
    },
    { title: "Created At", dataIndex: "created_at" },
    {
      title: "Action",
      render: (_: any, row: any) => (
        <Space>
          {!row.accepted_at && (
            <>
              <Tooltip title={"Edit"}>
                <EditOutlined
                  className="text-green-800"
                  size={18}
                  onClick={() => {
                    formRef.current.open(row);
                  }}
                />
              </Tooltip>
              <Tooltip title={"Delete"} placement="bottom">
                <Popconfirm
                  title={"Confirmation"}
                  description={`Are you sure, you want to delete this record?`}
                  onConfirm={() => onDelete(row.id)}
                >
                  <DeleteOutlined className="text-red-500" size={18} />
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        title="Invoices"
        desc="You can manage your invoices request from here"
      />
      <div className="p-5">
        <Form
          layout="vertical"
          onFinish={(values: any) => {
            values.period = values.period?.map((date: any) =>
              date.format(SYSTEM.DATE_FORMAT)
            );
            getInvoices(values);
          }}
        >
          <Space direction="horizontal">
            <Form.Item name={"vendor"} label="Vendor">
              <Input allowClear />
            </Form.Item>
            <Form.Item name={"period"} label="Time Period">
              <DatePicker.RangePicker allowClear />
            </Form.Item>
            <Form.Item name={"status"} label={"Status"}>
              <Select
                placeholder={`Select an option`}
                allowClear
                options={[
                  {
                    label: "Pending",
                    value: "pending",
                  },
                  {
                    label: "Approved",
                    value: "accepted",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                ]}
              />
            </Form.Item>
          </Space>
          <div className="flex gap-2">
            <Button className="submit-btn" htmlType="submit" loading={loading}>
              <FontAwesomeIcon icon={faFilter} />
              &nbsp; Filter
            </Button>
          </div>
        </Form>

        <Divider />

        <div className="flex justify-end mb-5 gap-2">
          <Button
            loading={loading}
            className="primary-btn"
            onClick={() => formRef.current.open()}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Add Invoice
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={list}
          loading={loading}
          size="small"
        />
        <InvoiceForm ref={formRef} onComplete={() => setReload(!reload)} />
      </div>
    </Layout>
  );
};

export default Invoices;

function getApproverStatus(data: any) {
  if (!data.accepted_at && !data.rejected_at) {
    return "Pending";
  } else if (data.accepted_at) {
    return "Approved";
  } else {
    return "Rejected";
  }
}

import React from 'react';

interface Props {
    title: string;
    className?: string;
    type?: 'primary' | 'danger' | 'success'
}

export const Badge = (props: Props) => {
    const {title, className = "", type} = props

    let colorClasses = "";
    switch (type) {
        case 'danger':
            colorClasses += "bg-red-100 text-red-600 ring-red-500/10" ;
            break;
            
        case 'success':
            colorClasses += "bg-green-100 text-green-600 ring-green-500/10" ;
            break;
    
        default:
            colorClasses += "bg-blue-100 text-blue-600 ring-blue-500/10" ;
            break;
    }

    return (<div className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset ${className} ${colorClasses}`}>{title}</div>)
}
import React, { useMemo, useState, useContext } from "react";
import {
  Dropdown,
  List,
  MenuProps,
  Popconfirm,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import Pouch from "pouchdb";

import { Button as CustomButton } from "../../components/Button";
import { BillItem } from "./BillItem";
import { PaymentMethod } from "./PaymentMethod";
import { PriceComponent } from "../../components/PriceComponent";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import AuthContext from "../../context/AuthContext";
import ReservationForm from "../reservations/ReservationForm";
import { roundToNearestHour } from "../../utils/helper";

const db = new Pouch("orders");
const draftDB = new Pouch("drafts");

export const BillContainer = ({ items, setItems }: any) => {
  const { user } = useContext(AuthContext);

  const discountRef: any = React.useRef();
  const draftRef: any = React.useRef();
  const formRef: any = React.useRef();
  const [method, setMethod] = useState<any>(null);
  const [discount, setDiscount] = useState<any>(null);

  const onRemove = (index: number) => {
    items.splice(index, 1);
    setItems([...items]);
  };

  const subTotal = useMemo(() => {
    return items.reduce(
      (a: any, b: any) => a + parseInt(b.price) * parseInt(b.quantity),
      0
    );
  }, [items]);

  const discountAmount: any = discount
    ? parseFloat(
        discount?.prefix === "%"
          ? (subTotal * discount.value) / 100
          : discount.value
      ).toFixed(0)
    : 0;
  const totalAmount = subTotal - discountAmount;

  const dropdownItems: MenuProps["items"] = [
    {
      label: "Add Draft",
      key: "1",
      onClick: () => {
        if (!items.length) return;
        draftDB.post({
          items,
          date: new Date(),
        });
        setItems([]);
      },
    },
    {
      label: "Drafts",
      key: "2",
      onClick: () => draftRef?.current.open(),
    },
    {
      label: "Reset",
      key: "3",
      onClick: () => setItems([]),
    },
  ];

  const onSubmit = async () => {

    let orderReq = {
      reservation_no: `${new Date().getTime()}`,
      payment: method?.title || "",
      discount_amount: discountAmount,
      created_at: new Date(),
      reservation_at: `${ moment().format('YYYY-MM-D') } ${ roundToNearestHour(moment()) }`,
      details: items.map((item: any) => ({
        service_id: item.id,
        name_en: item.name_en,
        name_ar: item.name_ar,
        quantity: item.quantity,
        unit_price: item.price,
        sub_amount: parseFloat(item.price) * parseInt(item.quantity),
      })),
      creator: {
        id: user.id,
        full_name: user.full_name,
      },
    };

    formRef.current.update(orderReq);
  };

  return (
    <div
      className="flex flex-col flex-1 bg-white shadow-sm py-2 px-5"
      style={{ height: "calc(100vh - 40px)" }}
    >
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-lg">Bills</h1>
        <Dropdown menu={{ items: dropdownItems }} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <MenuOutlined />
          </a>
        </Dropdown>
      </div>

      <List
        className="flex flex-1 flex-col"
        itemLayout="horizontal"
        style={{ overflowY: "auto" }}
        dataSource={items}
        renderItem={(item, index) => (
          <BillItem {...{ item, index, onRemove }} key={`item_${index}`} />
        )}
      />

      <div className="mt-3 font-semibold">
        <div className={`flex justify-between py-2 font-semibold`}>
          <span>Sub Total</span>
          <span className="font-regular text-gray-500">
            <PriceComponent value={subTotal} />
          </span>
        </div>

        {discount && (
          <div className={`flex justify-between py-2 font-semibold`}>
            <span>
              Discount ( {discount.prefix} {discount.value}){" "}
              <FontAwesomeIcon
                icon={faTimes}
                className="text-red-500"
                onClick={() => setDiscount(null)}
                role="button"
              />
            </span>
            <span className="font-regular text-gray-500">
              <PriceComponent value={`-${discountAmount}`} />
            </span>
          </div>
        )}

        <div className={`flex justify-between py-2 border-t font-semibold`}>
          <span>Total</span>
          <span className="font-regular text-gray-500">
            <PriceComponent value={`${totalAmount}`} />
          </span>
        </div>
      </div>

      {/* <Button
        type="dashed"
        onClick={() => discountRef?.current?.open()}
        block
        icon={<PlusOutlined />}
      >
        Add Discount & Others
      </Button> */}

      <PaymentMethod onChange={setMethod} />
      <CustomButton
        title={`Print Bill (${totalAmount}) - ${items.length} item(s)`}
        disabled={items.length === 0}
        className="w-full"
        onClick={onSubmit}
      />
      <DiscountLabel ref={discountRef} onSubmit={setDiscount} />
      <DraftList ref={draftRef} onSelect={(e: any) => setItems(e)} />
      <ReservationForm ref={formRef} />
    </div>
  );
};

const DiscountLabel = React.forwardRef((props: any, ref) => {
  const [form] = useForm<any>();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  const onSubmit = (values: any) => {
    props.onSubmit(values);
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      title={"Add Discount & Others"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      onOk={() => form.submit()}
      okButtonProps={{ className: "submit-btn" }}
    >
      <Form form={form} onFinish={onSubmit} className="mt-5" layout="vertical">
        <Form.Item
          label="Discount"
          name={"value"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Input addonBefore={prefixSelector} className="w-full" />
        </Form.Item>
      </Form>
    </Modal>
  );
});

const prefixSelector = (
  <Form.Item name="prefix" noStyle initialValue={"%"}>
    <Select style={{ width: 70 }} defaultValue={"%"}>
      <Select.Option value="-">-</Select.Option>
      <Select.Option value="%">%</Select.Option>
    </Select>
  </Form.Item>
);

const DraftList = React.forwardRef((props: any, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (visible) {
      getDrafts();
    }
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  const getDrafts = () => {
    draftDB
      .allDocs({
        descending: false,
        include_docs: true,
        attachments: true,
      })
      .then((res: any) => {
        setData(res.rows);
      });
  };

  const selectDraft = (item: any) => {
    props.onSelect(item.doc.items);
    setVisible(false);
  };

  const removeDraft = (item: any) => {
    draftDB
      .get(item.id)
      .then(function (doc) {
        return draftDB.remove(doc._id, doc._rev);
      })
      .then(function (result) {
        setData([...data.filter((i: any) => i.id != item.id)]);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <Modal
      title={"Draft Orders"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item: any, index) => (
          <List.Item role="button" className="gap-2">
            <FontAwesomeIcon
              icon={faTimes}
              className="text-red-500 z-10"
              onClick={() => removeDraft(item)}
            />
            <h1
              className="flex flex-1 justify-between"
              onClick={() => selectDraft(item)}
            >
              {item.doc.items.length} Services
              <p>{moment(item.doc.date).fromNow()}</p>
            </h1>
          </List.Item>
        )}
      />
    </Modal>
  );
});

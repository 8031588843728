import React from "react";
import { SYSTEM } from "../constant";

interface Props {
  value: string;
  prefix?: string | null;
  postfix?: string | null;
}

export const PriceComponent = ({ value, prefix = null, postfix = null }: Props) => {
  return (
    <span>
      <sup>{ prefix || SYSTEM.LEFT_CURRENCY}</sup>
      {" "}{value}{" "}
      <sup>{ postfix || SYSTEM.RIGHT_CURRENCY}</sup>
    </span>
  );
};

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Layout,
  Segmented,
  Select,
  Skeleton,
  Space,
  Table,
} from "antd";
import moment from "moment";
import { Header } from "../../components/Header";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { IMAGES, PAYMENTS, STATUS, SYSTEM } from "../../constant";
import { getFirstCharacters } from "../../utils/helper";
import {
  useNavigate,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export const UserView = ({ customerId }: any) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>({});
  const [section, setSection] = React.useState("Info");
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    navigate(`/customers/${customerId}/${section}`);
  }, [section]);

  useEffect(() => {
    setSection("Info");
    setLoading(true);
    setUser({});
    axios
      .get(`users/${customerId}`)
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setUser(res.data.response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  }, [customerId]);

  const sections: any = {
    Info: <UserInfo {...{ user, loading }} />,
    Appointments: <UserAppointments {...{ customerId }} />,
  };

  return (
    <div
      className="relative"
      style={{ height: "calc(100vh - 40px)", overflow: "auto" }}
    >
      <Header title={user.full_name} />
      <Segmented
        value={section}
        className="m-5 bg-slate-200 border-slate-400"
        onChange={(value: any) => {
          setSection(value);
        }}
        options={["Info", "Appointments"]}
      />
      {sections[section]}
    </div>
  );
};

export const UserInfo = ({ user, loading }: any) => {
  return (
    <Layout className="p-10">
      <Skeleton loading={loading}>
        <div className="flex flex-row gap-5">
          <Avatar src={user.profile_image_url || IMAGES.logo} size={100} className="bg-slate-600">
            {getFirstCharacters(user.full_name)}
          </Avatar>
          <div>
            <p className="text-xl">{user.full_name}</p>
            <p>Phone: {user.phone}</p>
            <p>Email: {user.email}</p>
            <p>Address: {user.address}</p>
            <p>Created Date: {moment(user.created_at).format("lll")}</p>
          </div>
        </div>
      </Skeleton>
    </Layout>
  );
};

export const UserAppointments = ({ customerId }: any) => {
  const [loading, setLoading] = useState<any>(false);
  const [list, setList] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`reservations`, {
        params: {
          consumer_id: customerId,
          ...filters,
        },
      })
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setList(res.data.response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  }, [customerId, filters]);

  const columns = [
    { title: "S.no", render: (_: any, __: any, index: number) => index + 1 },
    { title: "Outlet", dataIndex: ["outlet", "name"] },
    { title: "Creator", dataIndex: ["creator", "full_name"] },
    { title: "Therapist", dataIndex: ["therapist", "full_name"] },
    {
      title: "Customer",
      dataIndex: "consumer",
      key: "name",
      render: (consumer: any, row: any) =>
        `${consumer?.full_name} (${consumer?.phone || "N/A"})`,
    },
    {
      title: "Reservation At",
      dataIndex: ["reservation_at"],
      key: "reservation_at",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <p className="capitalize">{status || "pending"}</p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (payment: any) => <span className="capitalize">{payment}</span>,
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Created At",
      dataIndex: "updated_at",
      render: (date: string) => moment(date).format("lll"),
    },
    {
      title: "Action",
      key: "x",
      render: (_: any, row: any) => (
        <Space>
          {/* <Tooltip title={"Edit"}>
            <EditOutlined
              className="text-green-800"
              size={18}
              onClick={() => formRef.current.update(row)}
            />
          </Tooltip>
          <Tooltip title={"Delete"} placement="bottom">
            <Popconfirm
              title="Confirmation"
              description={"Are you sure you want to delete this user?"}
              onConfirm={() => onDelete(row.id)}
            >
              <DeleteOutlined className="text-red-500" size={18} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title={"Print"}>
            <PrinterOutlined size={18} onClick={() => onPrint(row)} />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  return (
    <Layout className="p-10">
      <Form
        layout="vertical"
        onFinish={(values: any) => {
          values.period = values.period?.map((date: any) =>
            date.format(SYSTEM.DATE_FORMAT)
          );
          setFilters({ ...values });
        }}
      >
        <Space direction="horizontal">
          <Form.Item name={"period"} label="Time Period">
            <DatePicker.RangePicker allowClear />
          </Form.Item>
          <Form.Item name={"payment"} label={"Payment Type"}>
            <Select
              placeholder={`Select an option`}
              allowClear
              options={PAYMENTS}
            />
          </Form.Item>
          <Form.Item name={"status"} label={"Status"}>
            <Select
              placeholder={`Select an option`}
              allowClear
              options={STATUS}
            />
          </Form.Item>
          <Form.Item name={"with_trash"} label={" "} valuePropName={"checked"}>
            <Checkbox value={1}> With Trashed</Checkbox>
          </Form.Item>
        </Space>
        <div className="flex gap-2">
          <Button className="submit-btn" htmlType="submit" loading={loading}>
            <FontAwesomeIcon icon={faFilter} />
            &nbsp; Filter
          </Button>
          {/* <DownloadButton
              fileUrl="reservations"
              params={{
                exportExcel: true,
                ...form.getFieldsValue(),
              }}
              downloadName={`orders_${moment().format("Y_M_D_HH_mm")}.xlsx`}
            /> */}
        </div>
      </Form>
      <Divider />

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={list}
        size="small"
        loading={loading}
        // rowClassName={(record, _) =>
        //   record.deleted_at ? "bg-red-200" : ""
        // }
      />
    </Layout>
  );
};

import React from "react";
import { List } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { SYSTEM } from "../../constant";

export const BillItem = ({ item, index, onRemove }: any) => {
  return (
    <List.Item>
      <div className="flex flex-col flex-1 px-2 font-semibold border-l-4 border-yellow-700">
        <div className="flex justify-between">
          {item.name_en}
          <DeleteFilled
            className="text-red-400"
            onClick={() => onRemove(index)}
          />
        </div>
        <div className="flex justify-between mt-1">
          <span className="font-semibold">x{item.quantity}</span>
          <span className="font-medium text-gray-500">
            <sup>{SYSTEM.LEFT_CURRENCY}</sup> {item.price * item.quantity}{" "}
            <sup>{SYSTEM.RIGHT_CURRENCY}</sup>
          </span>
        </div>
      </div>
    </List.Item>
  );
};

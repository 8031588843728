import React, { useContext, useEffect } from "react";
import {
  Col,
  List,
  Row,
  Input,
  Skeleton,
  Avatar,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { UserView } from "./UserView";
import { UserForm } from "./UserForm";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { getFirstCharacters } from "../../utils/helper";
import AuthContext from "../../context/AuthContext";


const Users = () => {
  const navigate = useNavigate()
  const formRef = React.useRef<any>();
  const {permissions} = useContext(AuthContext);
  const { customerId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [search, setSearch] = React.useState<any>('');

  useEffect(() => {
    const interval = setTimeout(() => {
      getUsers({search});
    }, 800)

    return () => clearInterval(interval);
  }, [reload, search]);

  const getUsers = async (params: any) => {
    setLoading(true);
    axios
      .get(`users`, {
        params: { role: "consumer", ...params },
      })
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setList(res.data.response.data);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <Row className="flex-1">
      <Col md={12} lg={8} xl={6} className="shadow-lg bg-white">
        <div className="flex justify-between border-b-2 p-4 ">
          <h1 className="text-lg font-semibold">Customers</h1>
          { permissions.includes('create_users') && <PlusOutlined
            className="text-xl"
            onClick={() => {
              formRef?.current.open();
            }}
          />}
        </div>
        <List
          itemLayout="horizontal"
          loading={loading}
          style={{ overflowY: "auto" }}
          dataSource={list}
          header={<div className="px-5">
            <Input.Search placeholder="Search here ..." loading={loading} onChange={(e) => {
              setSearch(e.target.value)
            }} />
          </div>}
          renderItem={(item: any, index) => (
            <List.Item actions={[
              ...( permissions.includes('edit_users') ? [<a key="list-loadmore-edit" onClick={(e) => {
                e.stopPropagation();
                formRef.current.open(item)
              } }>edit</a>] : [])
            ]} style={{paddingLeft: 10}} onClick={() => navigate(`/customers/${item.id}`) }>
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.profile_image_url} size={40} className="bg-slate-600">
                    {getFirstCharacters(item.full_name)}
                  </Avatar>}
                  title={<p className="font-semibold">{item.full_name}</p>}
                  description={item.phone}
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <UserForm ref={formRef} onComplete={() => setReload(!reload)} />
      </Col>
      <Col md={12} lg={16} xl={18}>
        {customerId && (
          <UserView {...{customerId}} />
        )}
      </Col>
    </Row>
  );
};

export default Users;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Layout,
  Space,
  Table,
} from "antd";
import { Header } from "../../components/Header";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import moment from "moment";
import AuthContext from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { SYSTEM } from "../../constant";
import { useForm } from "antd/es/form/Form";
import { ProductSelector } from "../../components/ProductSelector";

const Inventory = () => {
  const { user } = useContext(AuthContext);
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (user) getStocks();
  }, [user]);

  const getStocks = (params = {}) => {
    setLoading(true);
    axios
      .get(`/stocks`, { params: { outlet_id: user.outlet?.id, ...params } })
      .then((res) => {
        setLoading(false);
        if (res.status < 300) {
          setHistory(res.data.response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const columns = [
    {
      title: "Inventory",
      key: "inventory",
      render: (_: any, record: any) => record.inventory?.name,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string, row: any) => (
        <>
          <FontAwesomeIcon
            icon={!row.from_id ? faArrowUp : faArrowDown}
            className={!row.from_id ? "text-green-700" : "text-red-700"}
          />
          &emsp;{quantity}
        </>
      ),
    },
    {
      title: "Unit",
      key: "unit",
      render: (_: any, record: any) => record.inventory?.unit,
    },
    {
      title: "Transfer",
      dataIndex: "to_id",
      render: (_: string, row: any) => (
        <>
          {row.transfer_from?.name || "Warehouse"}&emsp;
          <FontAwesomeIcon icon={faArrowRight} />
          &emsp;{row.transfer_to?.name || "Warehouse"}
        </>
      ),
    },
    {
      title: "DateTime",
      dataIndex: "created_at",
      render: (date: any) => moment(date).format("lll"),
    },
  ];

  return (
    <Layout>
      <Header
        title="Inventory"
        desc="You can manage your inventory from here"
      />
      <div className="p-5">
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            values.period = values.period?.map((date: any) =>
              date.format(SYSTEM.DATE_FORMAT)
            );
            getStocks(values);
          }}
        >
          <Space direction="horizontal">
            <Form.Item name={"inventory_id"} label={"Product"}>
              <ProductSelector />
            </Form.Item>
            <Form.Item name={"period"} label="Time Period">
              <DatePicker.RangePicker allowClear />
            </Form.Item>
            <Form.Item
              name={"with_trash"}
              label={" "}
              valuePropName={"checked"}
            >
              <Checkbox value={1}> With Trashed</Checkbox>
            </Form.Item>
          </Space>
          <div className="flex gap-2">
            <Button className="submit-btn" htmlType="submit" loading={loading}>
              <FontAwesomeIcon icon={faFilter} />
              &nbsp; Filter
            </Button>
          </div>
        </Form>
        <Divider />
        <Table
          loading={loading}
          columns={columns}
          dataSource={history}
          size="small"
        />
      </div>
    </Layout>
  );
};

export default Inventory;

import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, MenuProps } from "antd";
import React from "react";

export const Header = ({ title = "", desc = "", children = false, options = [] }: any) => {

  return (
    <div
      className="bg-white border-b border-gray-200 min-h-14 px-5 py-2 flex flex-row items-center"
      style={{ minHeight: 62 }}
    >
      <div className="flex-1">
        <h1 className="text-lg font-semibold">{title}</h1>
        <p className="text-xs">{desc}</p>
        {children}
      </div>
      { options.length ? <Dropdown menu={{ items: options }} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()} className="p-3">
          <FontAwesomeIcon icon={faEllipsisVertical} className="text-lg" />
        </a>
      </Dropdown> : false}
    </div>
  );
};
